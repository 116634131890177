import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  close: 'close',
  closeWhite: 'closeWhite',
  loading: 'loading',
};

export type IconName = keyof typeof iconList;

export type IconSize = '12' | '16' | '18' | '20' | '24' | '28' | '32' | '36' | '50';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}
const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />);

Icon.defaultProps = {
  size: '20',
};

export default Icon;
