import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useConstellar = () => {
  const location = useLocation();

  useEffect(() => {
    const { constellar } = window as any;

    constellar?.pageview(location.pathname + location.search);
  }, [location]);
};

export default useConstellar;
