import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import React, { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import thankyouBg from 'assets/images/thank_you.png';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import NotifyModal from 'components/templates/NotifyModal';
import useWindowSize from 'hooks/useWindowSize';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { postReceiveScanQr, postReceiveScanQrV2 } from 'services/events';

const Home: React.FC = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorOther, setIsErrorOther] = useState(false);
  const [manualQRCode, setManualQRCode] = useState<number>();
  const { height } = useWindowSize();
  const [searchParams] = useSearchParams();
  const deviceId = getLocalStorage('deviceId');

  const params = useMemo(() => ({
    event_type: searchParams.get('event_type') || '',
    qr_uuid: searchParams.get('qr_uuid') || '',
    login_id: searchParams.get('login_id') || '',
  }), [searchParams]);
  const {
    data,
    isLoading,
  } = useVisitorData({ ignoreCache: false, }, { immediate: true });

  useEffect(() => {
    if (
      !!data
      && data.visitorId
      && params.qr_uuid
      && params.login_id
      && !isSubmit) {
      const postAction = async () => {
        try {
          (window as any).constellar?.action('receive-qr', { ...params, vid: data.visitorId });
          const res = params?.event_type ? await postReceiveScanQrV2({
            event_type: params?.event_type,
            qr_uuid: params.qr_uuid,
            login_id: params.login_id,
            device_id: data.visitorId,
            device_id_type: deviceId ? 'exist' : 'fresh',
          }) : await postReceiveScanQr({
            qr_uuid: params.qr_uuid,
            login_id: params.login_id,
            device_id: data.visitorId,
            device_id_type: deviceId ? 'exist' : 'fresh',
          });
          setManualQRCode(res.manualQrcode);
          setIsSubmit(true);
        } catch (error) {
          if ((error as Array<{ code: number; title: string }>)?.[0]?.code === 409) {
            setIsError(true);
          } else {
            setIsErrorOther(true);
          }
          setIsSubmit(true);
        }
      };
      postAction();
    }
  }, [data, params, isSubmit, deviceId]);

  useEffect(() => {
    if (!deviceId && data) {
      setLocalStorage('deviceId', data?.visitorId);
    }
  }, [data, deviceId]);

  if (!params || Object.keys(params).length < 2) {
    return (
      <div className="p-home_invalid">
        <Heading modifiers={['24x32', '700', 'center']}>
          Đường dẫn không hợp lệ, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  if (isLoading || !isSubmit) {
    return (
      <div className="loading">
        <Icon iconName="loading" size="50" />
      </div>
    );
  }

  if (isErrorOther) {
    return (
      <NotifyModal
        isOpen
        title="Mã QR không hợp lệ"
        desc="Mã QR không hợp lệ. Xin vui lòng thử lại mã khác"
      />
    );
  }

  if (isError) {
    return (
      <NotifyModal
        isOpen
        title="Mã QR đã sử dụng"
        desc="Mã QR đã được sử dụng. Xin vui lòng thử lại mã khác"
      />
    );
  }

  return (
    <div className="p-home" style={{ height: `${height}px` }}>
      <img src={thankyouBg} alt="present" loading="lazy" />
      {manualQRCode && isSubmit && (
        <div className="p-home_text">
          <Heading type="h2" modifiers={['white', '32x42', '700', 'center', 'ls-5']}>
            {manualQRCode}
          </Heading>
          <div className="p-home_warningText">
            <Text modifiers={['12x14', 'redOrange', '700', 'center', 'shadow']}>
              Mã QR tự động
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
